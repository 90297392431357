/* Basic Styling */
body, html {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* App Container */
.App {
  text-align: center;
  padding: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Keyframes for Logo Spin */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Navigation Styles */
nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

nav ul li {
  margin-right: 15px;
}

nav ul li:last-child {
  margin-right: 0;
}

nav a {
  text-decoration: none;
  color: #61dafb;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

nav a:hover {
  color: #21a1f1;
}

.active {
  font-weight: bold;
  color: #3498db;
}

/* Chatbot Styles */
.chatbot-iframe {
  width: 100%;
  height: 700px;
  border: none;
}

@media (max-width: 768px) {
  .chatbot-iframe {
    width: 100%;
    height: 700px;
  }
}

/* Button Styles */
button {
  cursor: pointer;
  background-color: #61dafb;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #21a1f1;
}

/* Miscellaneous */
.hidden {
  display: none;
}

.visible {
  display: block;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
}

.center {
  justify-content: center;
  align-items: center;
}

/* Utility Classes */
.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.text-white {
  color: white;
}

.bg-dark {
  background-color: #282c34;
}

/* Reset some common elements */
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

ul, ol {
  list-style: none;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}

/* Tailwind-inspired utility classes */
.shadow-sm {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.shadow-lg {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.rounded {
  border-radius: 5px;
}

.p-2 {
  padding: 8px;
}

.p-4 {
  padding: 16px;
}

.m-2 {
  margin: 8px;
}

.m-4 {
  margin: 16px;
}

/* Ensure images and videos fit within their containers */
img, video {
  max-width: 100%;
  height: auto;
}

.hidden {
  display: none !important;
}