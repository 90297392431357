/* Root variables */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

/* Header */
.header {
  background-color: var(--dark);
  color: var(--white);
  padding: 20px;
  text-align: center;
  font-size: 2em;
  font-family: var(--font-family-sans-serif);
  margin-bottom: 20px;
}

.banner-image {
  max-width: 100%;
  height: auto;
}

/* Section Headings */
.section-heading {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

/* Filters */
.filters {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
}
.filter-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: left;
}
.filter-buttons button {
  padding: 0px 0px;
  border-radius: 10px;
  background-color: var(--light);
  border: 1px solid var(--primary);
  color: var(--primary);
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.filter-buttons button:hover {
  background-color: var(--primary);
  color: var(--white);
}

/* Product Cards */
.product-card {
  border: 1px solid var(--light);
  border-radius: 10px;
  padding: 20px;
  background-color: var(--white);
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease;
  min-height: 400px; /* Ensuring consistent card height */
}
.product-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Buttons */
.button {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--primary);
  color: var(--white);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}
.button:hover {
  background-color: #0056b3;
}
.compare-button {
  background-color: var(--success);
}

/* Responsive Design */
@media (max-width: 768px) {
  .filters {
    flex-direction: column;
  }
  .product-card {
    margin: 10px 0;
  }
}

.battle-card-gallery {
  font-family: var(--font-family-sans-serif);
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.battle-card-gallery h2 {
  text-align: center;
  color: var(--gray-dark);
  margin-bottom: 20px;
}

.search-filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-input {
  position: relative;
  flex: 1;
  margin-right: 20px;
}

.search-input input {
  width: 100%;
  padding: 10px 40px 10px 10px;
  border: 1px solid var(--gray);
  border-radius: 5px;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--gray);
}

.category-filter {
  display: flex;
  align-items: center;
}

.category-filter select {
  padding: 10px;
  border: 1px solid var(--gray);
  border-radius: 5px;
  margin-left: 10px;
}

.filter-icon {
  margin-left: 10px;
  color: var(--gray);
}

.tag-filter, .sector-filter {
  margin-bottom: 20px;
}

.tag-buttons, .sector-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.tag-button, .sector-button {
  padding: 5px 10px;
  border: 1px solid var(--primary);
  border-radius: 20px;
  background-color: var(--white);
  color: var(--primary);
  cursor: pointer;
  transition: all 0.3s ease;
}

.tag-button.selected, .sector-button.selected {
  background-color: var(--primary);
  color: var(--white);
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.gallery-item {
  border: 1px solid var(--light);
  border-radius: 5px;
  padding: 20px;
  background-color: var(--white);
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease;
}
.gallery-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gallery-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.gallery-item-title {
  display: flex;
  align-items: center;
}

.gallery-item-name {
  font-size: 1.2em;
  font-weight: bold;
  margin-left: 10px;
}

.gallery-item-category {
  font-size: 0.9em;
  color: var(--gray);
}

.gallery-item-description {
  margin-bottom: 10px;
}

.gallery-item-pricing ul {
  list-style-type: none;
  padding-left: 0;
}

.gallery-item-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
}

.tag {
  background-color: var(--info);
  color: var(--white);
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 0.8em;
}

.expand-button, .compare-button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.expand-button {
  background-color: var(--primary);
  color: var(--white);
}

.compare-button {
  background-color: var(--gray);
  color: var(--white);
}

.compare-button.compared {
  background-color: var(--success);
}

.expanded-content {
  margin-top: 20px;
}

.expanded-content h4 {
  margin-bottom: 10px;
}

.expanded-content ul {
  list-style-type: none;
  padding-left: 0;
}

.resources {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.resource-link {
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
  color: var(--white);
}

.resource-link.datasheet {
  background-color: var(--info);
}

.resource-link.demo {
  background-color: var(--success);
}

.compare-floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: var(--primary);
  color: var(--white);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
}

.comparison-view {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.comparison-content {
  background-color: var(--white);
  padding: 20px;
  border-radius: 5px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
}

.comparison-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.comparison-item {
  border: 1px solid var(--light);
  border-radius: 5px;
  padding: 15px;
}

.close-comparison {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--primary);
  color: var(--white);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.iframe-container {
  width: 100%;
  padding: 20px 0;
}

.chatbot-iframe {
  width: 100%;
  height: 700px;
  border: 0;
}

.pbx-icon {
  width: 40px;
  height: 4opx;
}

@media (max-width: 768px) {
  .search-filter {
    flex-direction: column;
  }

  .search-input {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .category-filter {
    justify-content: space-between;
  }

  .filters {
    flex-direction: column;
  }

  .product-card {
    margin: 10px 0;
  }

  .chatbot-iframe {
    width: 100%;
    height: 700px; /* Ensuring enough space for the iframe */
    border: 0; /* Removing border */
  }
}
